// constant
import { ALL_SERVICES } from "redux/constants";
export const name = "allservices";

// initial state
export const initialState = {
  allServices: {
    data: [],
    isLoading: false,
    error: '',
    mfudata: [],
    isMFULoading: false,
    mfuerror: '',
    banners: [],
    isBannersLoading: false,
    bannerserror: '',
    helpfulResources: [],
    isHelpfulResourcesLoading: false,
    helpfulResourcesError: '',
    asCount: 0,
    isASCountLoading: false,
    asCountError: '',
    comingSoonData: [],
    isComingSoonLoading: false,
    comingSoonError: ''
  }
};
// reducer selectors
export const selectors = {
  getAllServices: (state) => state[name].allServices?.data,
  isallServicesLoading: (state) => state[name].allServices.isLoading,
  getMFU: (state) => state[name].allServices?.mfudata,
  isMFULoading: (state) => state[name].allServices.isMFULoading,
  getBanners: (state) => state[name].allServices?.banners,
  isBannersLoading: (state) => state[name].allServices.isBannersLoading,
  getHelpfulResources: (state) => state[name].allServices?.helpfulResources,
  isHelpfulResourcesLoading: (state) => state[name].allServices.isHelpfulResourcesLoading,
  getASCount: (state) => state[name].allServices?.asCount,
  isASCountLoading: (state) => state[name].allServices.isASCountLoading,
  getComingSoon: (state) => state[name].allServices?.comingSoonData,
  isComingSoonLoading: (state) => state[name].allServices.isComingSoonLoading,
};


export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ALL_SERVICES.GET_ALL_SERVICES_PENDING:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isLoading: true
        },
      });
    case ALL_SERVICES.GET_ALL_SERVICES_FAILURE:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isLoading: false,
          error: payload.error
        },
      });
    case ALL_SERVICES.GET_ALL_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isLoading: false,
          data: payload.data
        },
      });

    case ALL_SERVICES.GET_MFU_PENDING:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isMFULoading: true
        },
      });
    case ALL_SERVICES.GET_MFU_FAILURE:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isMFULoading: false,
          mfuerror: payload.error
        },
      });
    case ALL_SERVICES.GET_MFU_SUCCESS:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isMFULoading: false,
          mfudata: payload.data
        },
      });

    case ALL_SERVICES.GET_BANNERS_PENDING:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isBannersLoading: true
        },
      });
    case ALL_SERVICES.GET_BANNERS_FAILURE:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isBannersLoading: false,
          bannerserror: payload.error
        },
      });
    case ALL_SERVICES.GET_BANNERS_SUCCESS:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isBannersLoading: false,
          banners: payload.data
        },
      });

    case ALL_SERVICES.GET_HELPFUL_RESOURCES_PENDING:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isHelpfulResourcesLoading: true
        },
      });
    case ALL_SERVICES.GET_HELPFUL_RESOURCES_FAILURE:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isHelpfulResourcesLoading: false,
          helpfulResourcesError: payload.error
        },
      });
    case ALL_SERVICES.GET_HELPFUL_RESOURCES_SUCCESS:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isHelpfulResourcesLoading: false,
          helpfulResources: payload.data
        },
      });

    case ALL_SERVICES.GET_ACTIVE_SERVICES_COUNT_PENDING:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isASCountLoading: true
        },
      });
    case ALL_SERVICES.GET_ACTIVE_SERVICES_COUNT_FAILURE:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isASCountLoading: false,
          asCountError: payload.error
        },
      });
    case ALL_SERVICES.GET_ACTIVE_SERVICES_COUNT_SUCCESS:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isASCountLoading: false,
          asCount: payload.data
        },
      });

    case ALL_SERVICES.GET_COMING_SOON_PENDING:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isComingSoonLoading: true
        },
      });
    case ALL_SERVICES.GET_COMING_SOON_FAILURE:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isComingSoonLoading: false,
          comingSoonError: payload.error
        },
      });
    case ALL_SERVICES.GET_COMING_SOON_SUCCESS:
      return Object.assign({}, state, {
        allServices: {
          ...state.allServices,
          isComingSoonLoading: false,
          comingSoonData: payload.data
        },
      });

    default:
      return state;
  }
}
