// import LABELS from "labels";
import CONSTANTS from "globalConstants";

const {
  ERROR_401,
  ERROR_IE,
  ERROR_ENTITLEMENT_API,
  ERROR_CASE_RESTRICTED_MDP,
  ERROR_INVALID_CASE_GUID,
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    PRODUCTID,
    VIEW,
    CREATE_EDIT_DRAFT,
    PREVIEW_DOWNLOAD,
    PREVIEW_DOWNLOAD_R,
    EDIT_PUBLISHED_CONTENT,
    PUBLISH_CONTENT,
    SHOW_BUNDLE,
    CASE_PAGES,
    VIEW_CASE_DETAIL,
    VIEW_CLIENT_INFO,
    VIEW_BUDGET,
    EDIT,
    EDIT_TOPIC_TAGS,
    VIEW_CASE_MATERIALS,
  },
  VALIDATION_KEY,
  TBDBS: {
    SEARCH_SUBJECT,
    SEARCH_FPA_IPA_INTERNAL,
    FPA,
    IPA,
    BCG_INTERNAL
  },
} = CONSTANTS;

const CONFIG = {
  applicationName: "Transcript Library App",
  TRANSCRIPT_LIBRARY_VERSION: process.env.REACT_APP_VERSION,
  SKELETON_COUNT: [1, 2, 3, 4, 5],
  SERVER_URL: process.env.REACT_APP_FRONTEND_URL,
  ENVIRONMENT_MAIN: process.env.REACT_APP_ENV_MAIN,
  SKIPPED_THUMS_UPDOWN_ENABLED: process.env.REACT_APP_SKIP_CONDITION_THUMS_UPDOWN_ENABLED?.trim() === "true",
  DD: {
    AD: process.env.REACT_APP_DATADOG_RUM_APPID,
    CT: process.env.REACT_APP_DATADOG_RUM_CLIENTTOKEN,
    AN: process.env.REACT_APP_DATADOG_APP_NAME
  },
  OKTA: {
    ISSUER: process.env.REACT_APP_OKTA_ISSUER,
    REDIRECT_URL:
      "/implicit/callback" /* Must be whitelisted in the Okta client app settings. */,
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    ACCESS_TOKEN: "okta-token-storage",
    AUTH_ATTEMPTED: "auth_attempted",
    CACHE_STORAGE: "okta-cache-storage",
    PSID: "psid",
    SMARTLOGIC_TOKEN_KEY: "smartlogic_bearer_token",
    OKTA_PSID: "okta-psId",
  },
  SHARED_CONFIG: {
    GN_CO: {
      SP_XA: process.env.REACT_APP_STAFF_PHOTO_API_KEY,
      PP_XA: process.env.REACT_APP_PRIVACY_POLICY_API_KEY,
      SC_XA: process.env.REACT_APP_SEARCH_CONFIG_API_KEY,
    },
    ST_CO: {
      MAT_XA: process.env.REACT_APP_MATERIALS_API_KEY,
      CA_XA: process.env.REACT_APP_CASES_API_KEY,
      DV1_XA: process.env.REACT_APP_DIRECTORY_API_KEY,
      DV2_XA: process.env.REACT_APP_DIRECTORY_V2_API_KEY,
      BI_XA: process.env.REACT_APP_BCG_INTERNAL_API_KEY
    }
  },
  ERROR_CONFIG: {
    [ERROR_401]: {
      HEADING: "401",
      MESSAGE: "WE’RE SORRY, YOU DO NOT HAVE SUFFICIENT PRIVILEGES.",
      HELP_TEXT: "Please contact IT service Center to request access.",
      BTN_LABEL: "REQUEST ACCESS",
      BTN_LINK_NEW: true,
      BTN_LINK: "https://bostonconsultinggroup.service-now.com/itconnect?id=sc_cat_item&sys_id=d7f82a12db21d810b7529ec2ca961918",
      WITH_LAYOUT: true,
    },
    [ERROR_IE]: {
      HEADING: "IE11",
      MESSAGE: "WE ARE SORRY, NAVIGATOR DOESN'T SUPPORT INTERNET EXPLORER 11",
      HELP_TEXT: "PLEASE USE CHROME OR MICROSOFT EDGE. BOTH CAN BE INSTALLED FROM YOUR COMPUTER'S SOFTWARE CENTER.",
    },
    [ERROR_ENTITLEMENT_API]: {
      // HEADING: "? ?",
      IMAGE_HEADING: "bulb",
      MESSAGE: "LOOKS LIKE WE ARE EXPERIENCING AN ISSUE!",
      HELP_TEXT: "Please try to refresh the page, if the error still persists then please raise an issue.",
      BTN_LABEL: "REFRESH",
      BTN2_LABEL: "RAISE AN ISSUE",
      WITH_LAYOUT: false,
    },
    [ERROR_CASE_RESTRICTED_MDP]: {
      IMAGE_HEADING: "bulb",
      HELP_TEXT: "Access restricted to MDPs associated with this case!",
    },
    [ERROR_INVALID_CASE_GUID]: {
      IMAGE_HEADING: "bulb",
      HELP_TEXT: "Incorrect URL. Please check the URL and try again.",
    }
  },
  UI_URL: {
    ALL: "*",
    HOME: "/",
    MY_REQUESTS: "/my-requests",
    HELPFUL_RESOURCES: "/helpful-resources",
    ERROR: `/404`,

    CREATION: `/creation`,
    CREATION_KP: `/creation?type=kp`,

    METADATA_KB: (step, kbId) => kbId ? `/kb/metadata/${step}/${kbId}` : step ? `/kb/metadata/${step}` : `/kb/metadata/:step?/:kbId?`,
    METADATA_KP: (step, kpId) => kpId ? `/kp/metadata/${step}/${kpId}/` : step ? `/kp/metadata/${step}` : `/kp/metadata/:step?/:kpid?`,
    METADATA_KP_VALIDATE: (step, kpId) => kpId ? `/kp/metadata/${step}/${kpId}/${VALIDATION_KEY}` : step ? `/kp/metadata/${step}` : `/kp/metadata/:step?/:kpid?`,

    KP_PREVIEW_DETAILS: (kpId) => `/kp/preview/${kpId}`,
    KP_CONSUMPTION: (kpId) => `/kp/${kpId}`,
    KB_PREVIEW: (kbId) => `/kb/preview/${kbId}`,
    KB_CONSUMPTION: (kbId) => `/kb/${kbId}`,
    KP_DOWNLOAD: (appName, type, kpId) => `/download/kp/${appName}/${type}/${kpId}`,

    CP_METADATA: (step, cid) => cid ? `/cases/edit/${step}/${cid}` : step ? `/cases/edit/${step}` : `/cases/edit/:step?/:cid?`,
    CP_CONSUMPTION_NEW: (cid) => `/cases/${cid}`
  },

  API_URL: {
    // Global - Permissions & Entitlements
    GET_ENTITLEMENT: () =>
      `${process.env.REACT_APP_NGEN_API_URL}entitlement/users/public/entitlements`,
    GET_ACTIVE_SESSION_ID: () => `${process.env.REACT_APP_PB_API}/user/chat/session/active`,
    //People API
    PEOPLE_API: `${process.env.REACT_APP_API_PEOPLE}/domain/v2/people`,
    //Internal APIs
    GET_ALL_SERVICES_API: () => `${process.env.REACT_APP_CTS_INTERNAL_API}/caseteam/categories/list`,
    GET_MY_REQUEST_API: () => `${process.env.REACT_APP_CTS_INTERNAL_API}/caseteam/aggrecatorapi/getservicerequests`,
    GET_MFU_API: () => `${process.env.REACT_APP_CTS_INTERNAL_API}/caseteam/categories/mfu`,
    GET_ASC_API: () => `${process.env.REACT_APP_CTS_INTERNAL_API}/caseteam/aggrecatorapi/getactiverequestscount`,
    SAVE_MFU_API: () => `${process.env.REACT_APP_CTS_INTERNAL_API}/caseteam/categories/saveclick`,
    GET_BANNERS_API: () => `${process.env.REACT_APP_CTS_INTERNAL_API}/caseteam/categories/banners`,
    GET_HELPFUL_RESOURCES_API: () => `${process.env.REACT_APP_CTS_INTERNAL_API}/caseteam/categories/helpfulresources`,
    GET_COMING_SOON_API: () => `${process.env.REACT_APP_CTS_INTERNAL_API}/caseteam/categories/comingsoon`
  },
  IS_WALKME_ENABLED: process.env.REACT_APP_IS_WALKME_ENABLE?.trim() === "true",
  ENRICH_API_DOMAIN_KEY: `${process.env.REACT_APP_TL_ENRICH_API_KEY}`,
  ENRICH_API_DOMAIN: `${process.env.REACT_APP_TL_ENRICH_API}`,
  TL_DOCVIZ_S3_BUCKET_NAME: `${process.env.REACT_APP_TL_DOCVIZ_S3_BUCKET_NAME}`,
  TL_SEARCHABLE_S3_BUCKET_NAME: `${process.env.REACT_APP_TL_SEARCHABLE_S3_BUCKET_NAME}`,
  TL_GPT_ENGINE: `${process.env.REACT_APP_TL_GPT_ENGINE}`,
  USABILLA: {
    BUTTON_ID: "d0d0c7d779ff",
    MAPPING_SURVERY: {
      KP_PUBLISH: "a28c512331f4",
      CONSUMPTION_RATING: "bfa185fc00c0",
    },
    GET_USABILLA_NAME: (buttonId, analyticId) => `usbl.${buttonId}.c.${analyticId}`,
  },

  PEOPLE_API_X_API_KEY: process.env.REACT_APP_PEOPLE_API_X_API_KEY,
  X_API_KEY: process.env.REACT_APP_X_API_KEY,

  HTTP_CONTENT_TYPES: {
    json: "application/json",
    image: "image/jpeg",
    urlencoded: "application/x-www-form-urlencoded;charset=utf-8"
  },
  ENTITLEMENTS: {
    [KNOWLEDGE_CONTRIBUTIONS]: {
      [PRODUCTID]: 1,
      [VIEW]: 1,
      [CREATE_EDIT_DRAFT]: 2,
      [PREVIEW_DOWNLOAD]: 3,
      [PREVIEW_DOWNLOAD_R]: 4,
      [EDIT_PUBLISHED_CONTENT]: 5,
      [PUBLISH_CONTENT]: 6,
      [SHOW_BUNDLE]: 18
    },
    [CASE_PAGES]: {
      [PRODUCTID]: 2,
      [VIEW]: 7,
      [VIEW_CASE_DETAIL]: 8,
      [VIEW_CLIENT_INFO]: 9,
      [VIEW_BUDGET]: 10,
      [EDIT]: 11,
      [EDIT_TOPIC_TAGS]: 12,
      [VIEW_CASE_MATERIALS]: 13
    },
    APP_LEVEL_DISABLE_FLAG: process.env.REACT_APP_ENTITLEMENT_APP_LEVEL_DISABLE_FLAG?.trim() === "true",
  },
  BEARER: "Bearer ",
  PEOPLE_API_QUERY_PARAMS: {
    DEFAULT: {
      LIMIT: 500,
      OFFSET: 0,
      REQUEST_DATA: "id staffId title firstName picture lastName displayName jobFunction globalTitle email name alumni hostOfficeLocation { id name regionId street street2 street3 city state zipCode country countryCode }"
    },
    CASE_CONTACT_SEARCH: {
      LIMIT: 500,
      REQUEST_DATA: "id firstName lastName alumni staffId"
    },
    AUTHORS_LISTING: {
      REQUEST_DATA: "id firstName lastName alumni staffId"
    }
  },
  SORT: {
    ASC: "ASC",
    DESC: "DESC"
  },
  SHOW_DOCVIZ: process.env.REACT_APP_SHOW_DOCVIZ.trim() === "true" ? true : false,
  DOCVIZ_X_API_KEY: process.env.REACT_APP_DOCVIZ_X_API_KEY,
  DOCVIZ_POOLING_API_DATA: {
    TIME: 15000,
    INITIAL_TIME: 4000,
    ERROR_MSZ: 'Docviz Preview currently not available !',
    CALLBACK_KEYS: [
      "WIP",
      "CMP",
      "ERR",
    ]
  },
  DISPLAY_TEXT: {
    DEFAULT_ERROR: {
      TITLE: 'Oops, something went wrong!',
      MESSAGE: 'Something went wrong, please try again.'
    },
    ERROR_MESSAGES: {
      AUTH_FAILED: 'Authentication failed.',
      NOT_FOUND: 'The server could not find the data',
      FORBIDDEN: 'You do not have permissions to view this content',
      UNKNOWN_CODE: 'The server returned an unknown HTTP error code.',
      SERVER_ERROR: 'There was an external server error',
      NETWORK_ERROR: 'There was a network error.',
      REQUEST_CANCELLED: 'The request was cancelled.',
    }
  },
  API_KEYS: {
    UPLOAD_API_KEY: process.env.REACT_APP_UPLOAD_API_KEY,
    SMARTLOGIC_API_KEY: process.env.REACT_APP_SMARTLOGIC_API_KEY,
    SMP_API_KEY: process.env.REACT_APP_TL_SMP_API_KEY
  },
  POLY_HIERARCHICAL: process.env.REACT_APP_IS_POLY_ENABLED?.trim() === "true" ? [
    [
      { id: 'f7c063b3-e271-4130-ba40-54d4837aed2c', type: FPA },
      { id: '7fb35b48-257e-48e0-8991-7d5d678e3a23', type: FPA },
    ],
    [
      { id: '1db52330-f2db-45c4-93c0-85b376e594d9', type: FPA },
      { id: '33dbebf1-a9cc-4ad1-acd7-e829569d426a', type: FPA }
    ],
    [
      { id: 'b029ec4c-9bfc-432b-b0c7-807e577be6de', type: FPA },
      { id: 'b1b4b264-15e3-4f1d-af94-e5a62cde8126', type: FPA }
    ],
    [
      { id: '2307d865-a507-4d32-b684-d550f46761e0', type: FPA },
      { id: '5b9972bf-f202-4c33-bc75-75ad10f80299', type: FPA }
    ],
    [
      { id: '157c0d07-6a06-4cd7-9b39-8f2b621e31f4', type: FPA },
      { id: '933fe0db-c7b2-4cf3-9a85-38bb148a905f', type: FPA }
    ],
    [
      { id: 'e313dc31-16a1-417e-8c3f-dfcce429b98f', type: FPA },
      { id: '6d0059ae-a1c1-4503-b0e7-321287796980', type: FPA }
    ],
    [
      { id: 'fdabb807-c7c4-4ead-a499-d048007e5b17', type: FPA },
      { id: '7e4c03fe-e8b1-4521-8ea0-22893836e554', type: FPA }
    ],
    [
      { id: '9d49ed74-9291-4063-af11-f8ff02357f6d', type: IPA },
      { id: 'bc15e37b-78b1-4395-854c-ef31ff21f30e', type: IPA }
    ],
    [
      { id: 'c31ce2c8-5cdd-48df-94ba-325343561989', type: IPA },
      { id: 'c9c6267e-4e90-4447-ae38-b33b13f99245', type: IPA }
    ],
    [
      { id: 'e27ec256-12ba-46d5-a59e-a785a0105ac9', type: IPA },
      { id: 'fc6583b4-28cb-4ccf-a1d2-f676a4022d3b', type: IPA }
    ]
  ] : [],
  TAXONOMY_IDS: {
    IPA: () => `${process.env.REACT_APP_TAXONOMY_IPA}`,
    FPA: () => `${process.env.REACT_APP_TAXONOMY_FPA}`,
    BCG_INTERNAL: () => `${process.env.REACT_APP_TAXONOMY_BCG_INTERNAL}`,
    SUBJECT: () => `${process.env.REACT_APP_TAXONOMY_SUBJECT}`,
    TOPICPAGE_IPA: () => `${process.env.REACT_APP_TAXONOMY_TOPICPAGE_IPA}`,
    TOPICPAGE_FPA: () => `${process.env.REACT_APP_TAXONOMY_TOPICPAGE_FPA}`,
    REGIONS_COUNTRIES: () => `${process.env.REACT_APP_TAXONOMY_REGIONSCOUNTRIES}`,
    GLOBAL_REGION: "fb57a427-7b3f-44c1-938a-4d55c52f500e",
    GLOBAL_INTERNAL_OFFICE: "fab65ff9-cfc0-4401-8dc5-197fc9e608f0",
    IGNOREGUIDS_BST: [
      "19cc6dd8-e933-4c5d-9898-594fc77498b9", //IPA
      "56779bc7-52a4-4b26-a1f3-6f6560d028ec", //FPA
      "ecdf3e3b-c78f-4de4-9f6e-f9d798db4ddd"
    ]
  },
  TBDB_IDS: {
    [SEARCH_SUBJECT]: "Subjects",
    [SEARCH_FPA_IPA_INTERNAL]: "TypeAheadFPA-IPA-Internal",

    [FPA]: "FunctionalPracticeArea",
    [IPA]: "IndustryPracticeArea0",
    [BCG_INTERNAL]: "BCGInternal",
  },
  MAIL_TO_LABEL: (label) =>
    label ? ["subject", "ipa", "fpa"].includes(label.toLowerCase()) : false,
  MAIL_TO: (mode = true) => ({
    text: mode
      ? "Have questions related to Taxonomy"
      : "Can’t find the right Search and Discovery tag?",
    buttonText: "Contact us",
    email: "TaxonomyDevSupport@bcg.com",
    subject: "Request for new taxonomy term addition",
  }),

  DOWNLOAD_APPLICATION_NAME: {
    KNOWLEDGE_PAGE_APP_NAME: 'Transcript_Library'
  },
};

export default CONFIG;


